import React, { useContext, useEffect, useState } from "react"
import Helmet from "react-helmet"

import AppLayout from "../components/AppLayout"
import SEO from "../components/SEO"
import { JungContext } from "../../plugins/gatsby-plugin-jung-wrapper/JungContext"
import InitialiseAudio from "../components/InitialiseAudio"
import UnsupportedBrowser from "../components/UnsupportedBrowser"
import { useJungAudioSupport } from "../../plugins/gatsby-plugin-jung-wrapper/useJungAudioSupport"
import StreamSwitcher from "../components/StreamSwitcher"

import "./streams.scss"

const StreamsPage = (location) => {
    useEffect(() => {
        window.document.location = "https://wavepaths.com"
    }, [])

    return <div></div>
}

export default StreamsPage
